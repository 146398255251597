import React from 'react';

export default function Header() {
  return (
    <div className="Header">
      <header className="App-header bg-white text-black">
        <div className="container mx-auto px-4 py-8 flex items-center">
          <div className="mr-auto md:w-48 flex-shrink-0">
            <h1>VolunteerGoWhere</h1>
          </div>
        </div>
      </header>
    </div>
  );
}