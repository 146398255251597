export const msalConfig = {
    auth: {
      clientId: "8d7934e4-3922-4d73-9889-92115483709b",
      authority: "https://login.microsoftonline.com/43739721-55b4-4c3e-b0d9-763eb5ff9823", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: window.location.protocol + "//" + window.location.host,
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["User.Read"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  export const graphConfig = {
      graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };