import React from "react";
import { useMsal } from "@azure/msal-react";

export default function WelcomeMessage() {
    const { accounts } = useMsal();
    const name = accounts[0].name;
    
    return (
        <>
            <h5 className="card-title">Welcome {name}</h5>
        </>
    );
};