import React from 'react';

import { useIsAuthenticated } from '@azure/msal-react';
import Header from "../Header";
import MicrosoftSignInButton from '../MicrosoftSignInButton';
import MicrosoftSignOutButton from '../MicrosoftSignOutButton';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import WelcomeMessage from '../WelcomeMessage';
import { Outlet } from 'react-router-dom';

export default function Admin() {
    const isAuthenticated = useIsAuthenticated();

    return (
        <div>
            <Header />
            <hr />
            <main className="bg-gray-300">
                VolunteerGoWhere Admin
                <div>
                    <AuthenticatedTemplate>
                        <WelcomeMessage />
                        <Outlet />
                        <a href="/admin/new">Add an organisation</a>
                    </AuthenticatedTemplate>
                    <UnauthenticatedTemplate>
                        <p>You are not signed in! Please sign in.</p>
                    </UnauthenticatedTemplate>
                </div>
                <div>
                    {isAuthenticated ? <MicrosoftSignOutButton /> : <MicrosoftSignInButton />}
                </div>
            </main>
        </div>
    );
}