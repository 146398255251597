import React from 'react';
import { Routes, Route } from "react-router-dom";
import Admin from './admin/Admin';
import AdminPanel from './admin/AdminPanel';
import NewOrgPanel from './admin/NewOrgPanel';
import Home from './Home';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="admin" element={<Admin />}>
          <Route path="/admin" element={<AdminPanel />} />
          <Route path="new" element={<NewOrgPanel />} />
        </Route>
      </Routes>
    </div >
  );
}

export default App;