import React from 'react';
import PlaceHolderImage from '../placeholder.png';
import { OrgData } from '../types';

export default function OrgCard(props: OrgData) {
  return (
    <div className="transition-all ease-in-out duration-1000 flex justify-center ml-0 md:ml-2">
      <div className="max-w-xs">
        <div className="p-5 shadow-md m-2 mt-4"><img className="object-scale-down h-30" src={PlaceHolderImage} alt="step3" />
          <div className="text-xs font-bold uppercase text-teal-700 mt-1 mb-2">{props.category}</div>
          <div className="text-xl font-bold mb-2">{props.name}</div>
          <div className="text-base">
            {props.description}
            <br />
          </div>
          <div className="phoneNumber text-sm text-gray-600">
            <a href={"tel:" + props.phone}>{props.phone}</a>
          </div>
          <div className="website text-sm text-gray-600">
            <a href={"" + props.website}>{props.website}</a>
          </div>
          <div className="truncate"></div>
        </div>
      </div>
    </div>
  );
}