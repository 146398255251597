import React from 'react';
import { useGetOrgsQuery } from '../../services/organisations';
import OrgListing from './OrgListing';

export default function AdminPanel() {
    const { data, error, isLoading } = useGetOrgsQuery();
    let orgListings;

    if (error) {
        return (
            <div role='alert'>
                An error has occurred while loading the page.
            </div>
        )
    }

    if (isLoading) {
        return (
            <div>
                Loading...
            </div>
        )
    }

    if (data && data.map) {
        orgListings = data.map((data) => {
            return <OrgListing key={data.name as string} {...data} />;
        });
        
        return (
            <div id="listings" className="flex bg-gray-50">
                <div>
                    {orgListings}
                </div>
            </div>
        );
    }

    return (
        <div>No valid organisations were returned by the server.</div>
    )
}