import React from 'react';
import { OrgData } from '../../types';

export default function OrgListing(props: OrgData) {
    return (
        <div className="transition-all ease-in-out duration-1000 flex flex-col justify-center ml-0 md:ml-2">
            <div className="max-w-xs">
                <div className="text-xl font-bold mb-2">{props.name}</div>
            </div>
        </div>
    );
}