import React from 'react';
import OrgCard from './OrgCard';
import { useGetOrgsQuery } from '../services/organisations';

export default function Listings() {
  const { data, error, isLoading } = useGetOrgsQuery();
  let orgCards;

  if(error) {
    return( 
    <div role='alert'>
      An error has occurred while loading the page.
    </div>
    )
  }

  if(isLoading) {
    return(
      <div>
        Loading...
      </div>
    )
  }

  if(data && data.map) {
    orgCards = data.map((data) => {
      return <OrgCard key={data.name as string} {...data} />;
    });
  
    return (
      <div id="listings" className="flex flex-wrap bg-gray-50">
          { orgCards }
      </div>
    );
  };
  
  return(
    <div>No valid organisations were returned by the server.</div>
  )
}