import React, { useState } from 'react'
import { useAddNewOrgMutation } from '../../services/organisations'
import { ImageFile } from '../../utils/imageFile';

export const NewOrgPanel = () => {
    const [name, setName] = useState('');
    const [category, setCategory] = useState('');
    const [image, setImage] = useState<ImageFile>();
    const [description, setDescription] = useState('');
    const [phone, setPhone] = useState('');
    const [website, setWebsite] = useState('');
    const [saveMessage, setSaveMessage] = useState('');

    const [addNewOrg, { isLoading }] = useAddNewOrgMutation()

    const onNameChanged = (e: { target: { value: React.SetStateAction<string> } }) => setName(e.target.value)
    const onCategoryChanged = (e: { target: { value: React.SetStateAction<string> } }) => setCategory(e.target.value)
    const onImageChanged = (e: { target: React.SetStateAction<HTMLInputElement> }) => {
        setImage(ImageFile.from((e.target as HTMLInputElement).files![0]))
    }

    const onDescriptionChanged = (e: { target: { value: React.SetStateAction<string> } }) => setDescription(e.target.value)
    const onPhoneChanged = (e: { target: { value: React.SetStateAction<string> } }) => setPhone(e.target.value)
    const onWebsiteChanged = (e: { target: { value: React.SetStateAction<string> } }) => setWebsite(e.target.value)

    const canSave = Boolean(name) && Boolean(description) && Boolean(category) && !isLoading

    const onSaveOrgClicked = async () => {
        try {
            await addNewOrg({ body: { name: name, description: description, phone: phone, category: category, website: website, image: image } }).unwrap()
            setSaveMessage("Organisation saved!")
        } catch (err) {
            setSaveMessage("There was a problem: " + JSON.stringify(err))
        }
    }

    return (
        <section>
            <h2>Add a New Organisation</h2>
            <div role="alert">{saveMessage}</div>
            <form>
                <div>
                    <label htmlFor="orgName">Org Name:</label>
                    <input
                        type="text"
                        id="orgName"
                        name="orgName"
                        placeholder="A very charitable organisation"
                        value={name}
                        onChange={onNameChanged}
                    />
                </div>
                <div>
                    <label htmlFor="orgCategory">Category:</label>
                    <input
                        type="text"
                        id="orgCategory"
                        name="orgCategory"
                        placeholder="Environmental"
                        value={category}
                        onChange={onCategoryChanged}
                    />
                </div>
                <div>
                    <label htmlFor="orgImage">Picture:</label>
                    <input
                        type="file"
                        id="orgImage"
                        name="orgImage"
                        accept="image/*"
                        placeholder="An image that represents your organisation"
                        onChange={onImageChanged}
                    />
                </div>
                <div>
                    <label htmlFor="orgDescription">Description:</label>
                    <input
                        type="text"
                        id="orgDescription"
                        name="orgDescription"
                        placeholder="This is the description of an organisation."
                        value={description}
                        onChange={onDescriptionChanged}
                    />
                </div>
                <div>
                    <label htmlFor="orgPhone">Phone Number:</label>
                    <input
                        type="text"
                        id="orgPhone"
                        name="orgPhone"
                        placeholder="8888 8888"
                        value={phone}
                        onChange={onPhoneChanged}
                    />
                </div>
                <div>
                    <label htmlFor="orgPhone">Website:</label>
                    <input
                        type="text"
                        id="orgWebsite"
                        name="orgWebsite"
                        placeholder="https://website.com.sg"
                        value={website}
                        onChange={onWebsiteChanged}
                    />
                </div>
                <button id="saveButton" type="button" onClick={onSaveOrgClicked} disabled={!canSave}>
                    Save Org
                </button>
            </form>
        </section>
    )
}

export default NewOrgPanel;
