import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { IPublicClientApplication } from "@azure/msal-browser";

function handleLogin(instance: IPublicClientApplication) {
    instance.loginRedirect(loginRequest).catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will open a popup for login
 */
 export default function MicrosoftSignInButton() {
    const { instance } = useMsal();

    return (
        <button className="ml-auto" onClick={() => handleLogin(instance)}>Sign in</button>
    );
}