import React from 'react';

import Header from "./Header";
import Listings from "./Listings";

export default function Home() {
    return (
        <div>
            <Header />
            <hr />
            <main className="bg-gray-300">
                <Listings />
            </main>
        </div>
    );
}