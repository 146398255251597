import { encode } from "base64-arraybuffer"

export class ImageFile {
    name: String
    type: String
    size: Number
    bits: String

    constructor(originalFile: File) {
        this.name = originalFile.name
        this.type = originalFile.type
        this.size = originalFile.size
        this.bits = ""
    }

    public static from(file: File): ImageFile {
        let imageFile = new ImageFile(file)
        this.encodeBits(imageFile, file)
        return imageFile
    }

    private static encodeBits(imageFile: ImageFile, bitsFile: File) {
        // For some reason, node doesn't seem to have the arrayBuffer() function on files and explodes.
        // We test to see if it exists and skip this if it doesn't, so at least we can still test other pieces
        // of ImageFile functionality.
        // There should be a corresponding comment in the tests.  If this can be fixed and run successfully in node,
        // delete this "if" statement and enable the proper testing of assiging bits to ImageFiles.
        if (bitsFile.arrayBuffer) {
            bitsFile.arrayBuffer().then(function (buffer) {
                imageFile.bits = encode(buffer)
            })
        }
    }
}