import React from "react";
import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";



function handleLogout(instance: IPublicClientApplication) {
    instance.logoutRedirect().catch(e => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will open a popup for login
 */
 export default function MicrosoftSignOutButton() {
    const { instance } = useMsal();

    return (
        <button className="ml-auto" onClick={() => handleLogout(instance)}>Sign out</button>
    );
}